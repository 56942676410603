import revive_payload_client_YDqlFGJzj6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_W43gZTJXHV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Hv2ETisOku from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tzmH66vnPZ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_L19iIuLTIw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cvKNCjmg8T from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kU2LZIZSFj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hjNWamOhRd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_flC2hNmGsr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_typescript@5.6.3_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ZXBW6S44aP from "/opt/buildhome/repo/node_modules/.pnpm/dayjs-nuxt@2.1.11/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_Woubn1VGg6 from "/opt/buildhome/repo/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.59.16_nuxt@3.13.2/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import auth_redirect_7YxV930Bgu from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_client_h3HHNI5Rq6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-viewport@2.2.0_vue@3.5.12/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import slideovers_Oy2HgRxdUn from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_V3S10kvYNY from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_gBhSDkKzWX from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_eNelkHx7DD from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_Nt2P1JFqxq from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.8_vite@5.4.10_vue@3.5.12/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import firebase_Wjz9XrZo01 from "/opt/buildhome/repo/plugins/firebase.ts";
import vue_mountable_fz1MuxJRc2 from "/opt/buildhome/repo/plugins/vue-mountable.js";
export default [
  revive_payload_client_YDqlFGJzj6,
  unhead_W43gZTJXHV,
  router_Hv2ETisOku,
  supabase_client_tzmH66vnPZ,
  payload_client_L19iIuLTIw,
  navigation_repaint_client_cvKNCjmg8T,
  check_outdated_build_client_kU2LZIZSFj,
  chunk_reload_client_hjNWamOhRd,
  components_plugin_KR1HBZs4kY,
  prefetch_client_flC2hNmGsr,
  plugin_ZXBW6S44aP,
  plugin_Woubn1VGg6,
  auth_redirect_7YxV930Bgu,
  plugin_client_h3HHNI5Rq6,
  slideovers_Oy2HgRxdUn,
  modals_V3S10kvYNY,
  colors_gBhSDkKzWX,
  plugin_client_eNelkHx7DD,
  plugin_Nt2P1JFqxq,
  firebase_Wjz9XrZo01,
  vue_mountable_fz1MuxJRc2
]