<script setup>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

useHead({
  title: "SyncPost",
});
</script>

<template>
  <div class="">
    <!-- <NuxtLoadingIndicator/> -->
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <UNotifications />
    <UModals />
  </div>
</template>

<style>
body,
#__nuxt,
html {
  height: 100%;
  font-family: "Public Sans", sans-serif;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
</style>
