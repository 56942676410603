import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig();

	const firebaseConfig = {
		apiKey: "AIzaSyA9ICvDSnfmZp1cWtPN8nrC-pZA0zda7tg",
		authDomain: "magnetrewire-2ca44.firebaseapp.com",
		projectId: "magnetrewire-2ca44",
		storageBucket: "magnetrewire-2ca44.appspot.com",
		messagingSenderId: "23391691003",
		appId: "1:23391691003:web:a7380586e036aef172d05e",
	};
	const app = initializeApp(firebaseConfig);

	const auth = getAuth(app);
	const firestore = getFirestore(app);

	nuxtApp.vueApp.provide('auth', auth)
    nuxtApp.provide('auth', auth)

    nuxtApp.vueApp.provide('firestore', firestore)
    nuxtApp.provide('firestore', firestore)
});
